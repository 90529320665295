import axios from "@/plugins/axios";

const state = {
    carts       : [],
    sub_total   : 0
}
const getters = {
    carts       : state => state.carts,
    sub_total   : state => state.sub_total
}
const mutations = {
    SET_CARTS(state, carts) {
        state.carts = carts;
    },
    SET_SUB_TOTAL(state, sub_total) {
        state.sub_total = sub_total;
    }
}
const actions = {
    async getUserCarts({commit}, params) {
        await axios.get(`/carts/get-cart/${params}`).then(res=>{
            commit('SET_CARTS', res.data.data.carts);
            commit('SET_SUB_TOTAL', res.data.data.sub_total)
        })
    },
    async addCart({commit, dispatch}, params) {
        if(params) {
            await axios.post('/carts/add-cart', params).then(res=>{
                if(res.code == 200) {
                    return true;
                }
            }).catch(err=>{
                return false;
            });
        }
    }
}

export default {
    namespaced: true,
    state,  
    getters,
    mutations,
    actions
};