import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import LoaderSkeleton from '@/components/common/LoaderSkeleton.vue';
import OnScroll from '@/components/common/OnScroll.vue';
import BottomDrawer from '@/components/common/BottomDrawer.vue';
import Filters from '@/components/common/Filters.vue';

export default {
    components: {
        Icon,
        LoaderSkeleton,
        OnScroll,
        BottomDrawer,
        Filters
    },
    data() {
        return {
            all_products: [],
            filter_category: [],
            product_loader: false,
            bottom_drawer: false,
            sizes: [],
            filter: {
                skip: 0,
                take: 12,
                category_id: 'all'
            },
            pagination: {
                current_page: 1,
                last_page: 1,
                total_pages: 0,
            },
            search_filter: null
        };
    },
    computed: {
        visiblePages() {
            const pages = [];
            const current = this.pagination.current_page;
            const last = this.pagination.last_page;
            const maxVisible = 5; // Number of visible pages in the middle

            let startPage = Math.max(current - Math.floor(maxVisible / 2), 1);
            let endPage = Math.min(startPage + maxVisible - 1, last);

            if (endPage - startPage < maxVisible - 1) {
                startPage = Math.max(endPage - maxVisible + 1, 1);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    methods: {
        async getAllProducts() {
            this.product_loader = true;
    
            // Build the URL with filters and pagination
            let url = `/products/all?products=true&page=${this.pagination.current_page}&skip=${this.filter.skip}&take=${this.filter.take}`;
            const categoryId = this.filter.category_id !== 'all' ? this.filter.category_id : '';
            
            // Add category and search filter if they exist
            if (categoryId) {
                url += `&category_id=${categoryId}`;
            }
            if (this.search_filter) {
                url += `&search_filter=${this.search_filter}`;
            } else if (this.$route.query.search_product) {
                url += `&search_filter=${this.$route.query.search_product}`;
            }

            try {
                const res = await axios.get(url);

                // Adjust to the correct structure if needed
                if (res.data.data && res.data.data.data) {
                    this.all_products = res.data.data.data || [];
                    this.pagination.current_page = res.data.data.current_page || 1;
                    this.pagination.last_page = res.data.data.last_page || 1;
                    this.pagination.total_pages = res.data.data.total || 0;
                } else {
                    // Default to empty data if response format is unexpected
                    this.all_products = [];
                    this.pagination.current_page = 1;
                    this.pagination.last_page = 1;
                    this.pagination.total_pages = 0;
                }
                
            } catch (error) {
                console.error("Error fetching products: ", error);
            } finally {
                this.product_loader = false;
            }
        },
        fetchPage(page) {
            this.filter.skip = (page - 1) * this.filter.take;
            this.pagination.current_page = page;
            this.getAllProducts();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        getFilterCategories() {
            axios.get('/category/filters').then((res) => {
                if (res.data.code === 200) {
                    this.filter_category = res.data.data;
                }
            }).catch(error => {
                this.filter_category = [];
            });
        },
        getAllSize() {
            axios.get('/size/').then((res) => {
                this.sizes = res.data.data;
            }).catch((err) => {
                this.sizes = [];
            });
        },
        handleSelectedCategory(value) {
            this.pagination.current_page = 1;
            this.filter.category_id = value;
            this.getAllProducts();
            this.bottom_drawer = false;
        },
    },
    mounted() {
        this.getAllProducts();
        this.getFilterCategories();
        this.getAllSize();
    }
}