<template>
    <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div class="flex items-center justify-center">
            <Icon icon="game-icons:shaking-hands" class="size-16 md:size-24 p-3 rounded-full bg-gray-100 text-red-400 mb-4"/>
        </div>
        <div class="text-center">
            <h1 class="text-2xl md:5xl font-bold">Thanks for your order! <router-link class="underline text-red-300" :to="{ name: 'orderDetail', params: { id: this.$route.params.id } }">{{ detail.invoice_no }}</router-link> </h1>
        </div>
        <div class="text-center my-4">
            <p class="text-sm md:text-base font-semibold">Hello, and maraming salamat! Your purchase from Wright's Tender Meat is on its way to bringing quality, authentic Filipino flavors and tender cuts right to your kitchen. We’re thrilled to have you as part of our family and can't wait for you to enjoy our carefully selected meats and Filipino delicacies.</p>
            <br>
            <p class="text-sm md:text-base font-semibold">Stay tuned for your order updates, and feel free to reach out if you need any assistance. Happy cooking, and salamat muli!</p>
            <div class="mt-8 text-center">
                <p class="text-sm md:text-base font-semibold text-gray-400">All the best,</p>
                <div class="flex justify-center">
                    <img :src="$static_site('logo.png')" class="w-[100px]" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
export default {
    components: {
        Icon
    },
    data() {
        return {
            detail: []
        }
    },
    mounted() {
        axios.get('/carts/my-orders/detail/'+parseInt(this.$route.params.id)).then(res=>{
            if(res.status == 200) {
                this.detail = res.data.data;
            }
        })
        .catch(err=>{});
    }
}
</script>