<template>
    <div class="fixed z-20 bottom-0 left-0 bg-gray-50 shadow-lg w-full h-[85vh] p-3 ronded-top">
        <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>