<template>
    <section class="relative flex flex-wrap lg:h-screen lg:items-center">
        <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
            <div class="mx-auto max-w-lg text-center">
            <h1 class="text-2xl font-bold sm:text-3xl">Regsiter now to get your meat today!</h1>
    
            <p class="mt-4 text-gray-500">
                Sign up now to get access to premium cuts of fresh meat, carefully prepared and ready for your enjoyment!
            </p>
            </div>
    
            <form @submit.prevent="submitLoginHandler" class="mx-auto mb-0 mt-8 max-w-md space-y-4">
                <div>
                <div class="relative">
                    <label
                        class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                    >
                        <input
                        v-model="form.name"
                        type="text"
                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                        placeholder="Username"
                        />
                    
                        <span
                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                        >
                        Fullname
                        </span>
                    </label>
                    </div>
                </div>
                <div class="my-2" v-if=" error.name">
                <span class="text-red-500">{{ error.name }}</span>
            </div>

            <div>
                <div class="relative">
                    <label
                        class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                    >
                        <input
                        v-model="form.email"
                        type="email"
                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                        placeholder="Username"
                        />
                    
                        <span
                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                        >
                        Email
                        </span>
                    </label>
                    </div>
                </div>
                <div class="my-2" v-if=" error.email">
                <span class="text-red-500">{{ error.email }}</span>
            </div>
        
                <div>
                    <div class="relative">
                    <label
                        class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                    >
                        <input
                        v-model="form.password"
                        type="password"
                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                        placeholder="Username"
                        />
                    
                        <span
                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                        >
                        Password
                        </span>
                    </label>
                    </div>
                </div>
                <div class="my-2" v-if=" error.password">
                <span class="text-red-500">{{ error.password }}</span>
            </div>

                <div>
                    <div class="relative">
                    <label
                        class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                    >
                        <input
                        v-model="form.confirm_password"
                        type="password"
                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                        placeholder="Username"
                        />
                    
                        <span
                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                        >
                        Confirm Password
                        </span>
                    </label>
                    </div>
                </div>
                <div class="my-2" v-if=" error.confirm_password">
                <span class="text-red-500">{{ error.confirm_password }}</span>
            </div>
        
                <div class="flex items-center justify-between">
                    <p class="text-sm text-gray-500">
                    Already had an acount?
                    <router-link to="/sign-in" class="underline">
                        Sign In
                    </router-link>
                    </p>
            
                    <button
                    type="submit"
                    class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
                    :disabled="loading"
                    >
                    <span v-if="!loading">Register</span>
                    <span v-else>Loading...</span>
                    </button>
                </div>
            </form>
        </div>
    
      <div class="relative h-64 w-full sm:h-96 lg:h-full lg:w-1/2">
        <img
          alt=""
          :src="$static_site('/aboutus_slider_gallery/slider-0.jpg')"
          class="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </section>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  import axios from '../plugins/axios';
  
    export default {
      data() {
        return {
          form: {
            name  : null,
            email     : null,
            password  : null,
            confirm_password: null
          },
          login_flag  : null,
          login_msg   : null,
          loading     : false,
          error       : {
            name: null,
            email: null,
            password: null,
            confirm_password: null
          }
        }
      },
      methods: {
        ...mapActions({
          setAuthUser: 'auth/setAuthUser'
        }),
        submitLoginHandler() {
          this.loading = true;
          this.error.name = null;
          this.error.password = null;
          this.error.email = null;
          this.error.confirm_password = null;

          if(this.form.password != this.form.confirm_password) {
            this.error.confirm_password = "The Password and Confirm Password not match."
            this.loading = false;
            return
          }

          axios.post('/customer/register', this.form).then((res)=>{
            if(this.setAuthUser(res)) {
              this.$router.push('/sign-in');
              this.loading = false;
            }
          }).catch((err)=>{
            if(err.validation.name) {
                this.error.name = err.validation.name[0]
            }
            if(err.validation.email) {
                this.error.email = err.validation.email[0]
            }
            if(err.validation.password) {
                this.error.password = err.validation.password[0]
            }
            this.loading = false;
          });

          this.loading = false;
        }
      }
    }
  </script>