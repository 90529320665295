function static_site(name) {
    return `https://wrightstendermeats.com.au/assets/uploads/${name}`;
}
function logo_site(name) {
    return `https://wrightstendermeats.com.au/assets/uploads/${name}`;
}

export {
    static_site,
    logo_site
}