<template>
    <section>
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-2 lg:grid-cols-[0.4fr_1fr]">
                <!-- Filters -->
                <Filters class="flex hidden lg:block" :category="filter_category" :sizes="sizes" @category_id="handleSelectedCategory"/>

                <div>
                    <div class="py-2 md:py-3">
                        <div class="grid grid-cols-2 gap-2 lg:grid-cols-[1fr_0.6fr]">
                            <h1 class="font-semibold text-lg md:text-xl lg:text-3xl">
                                All Product Meats
                            </h1>
                            <div class="flex items-center justify-end">
                                <Icon 
                                    class="flex lg:hidden text-base font-bold ms-3" 
                                    icon="mdi:equaliser-vertical" 
                                    @click="bottom_drawer = !bottom_drawer"
                                />
                            </div>
                        </div>

                        <!-- All Products -->
                        <div class="mt-3">
                            <div v-if="product_loader" class="w-full">
                                <LoaderSkeleton />
                            </div>
                            <div v-else >
                                <div 
                                    v-if="all_products.length"
                                    class="grid gap-4 grid-cols-2 md:grid-cols-3"
                                >
                                    <div v-for="(data,index) in all_products" :key="index">
                                        <router-link :to="{ name: 'detailMeatPage', params: { id: data.product_id } }" params class="group relative block overflow-hidden hover:shadow">
                                            <img
                                            :src="$static_site(data.product_image)"
                                            alt=""
                                            class="h-64 w-full rounded-lg object-cover transition duration-500 group-hover:scale-105 sm:h-72"
                                            />
                                        
                                            <div class="relative bg-white p-6">
                                                <h3 class="mt-4 text-lg font-medium text-gray-900">{{ data.product_name }}</h3>
                                                <ul>
                                                    <div class="flex justify-start gap-0.5 text-yellow-500">
                                                        <div v-for="(star, index) in 5" :key="index">
                                                            <div v-if="index != parseInt(data.ratings) && data.ratings != null ">
                                                                <Icon icon="mdi:star" />
                                                            </div>
                                                            <div v-else>
                                                                <Icon icon="mdi:star-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                                <p class="mt-1.5 text-lg font-bold text-gray-700">
                                                    ${{ data.product_current_price }} 
                                                    <span v-if="data.product_old_price" class="text-gray-400 line-through">
                                                        ${{ data.product_old_price }}
                                                    </span> 
                                                    <span v-if="data.product_old_price" class="ms-2 text-xs text-gray-300 font-normal bg-red-500 p-1 rounded">{{ $discount_percentage(data.product_old_price, data.product_current_price) }}%</span>
                                                </p>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- Pagination -->
                                <div v-else class="w-full flex items-center justify-center mt-7">
                                    <h1 class="text-lg md:text-xl font-semibold">No Products</h1>
                                </div>

                                <!-- Pagination Buttons -->
                                <div class="pagination mt-5 flex justify-center" v-if="all_products.length">
                                    <!-- Previous Button -->
                                    <button
                                        v-if="pagination.current_page > 1"
                                        @click="fetchPage(pagination.current_page - 1)"
                                        class="rounded-full">
                                        Previous
                                    </button>

                                    <!-- First Page Button -->
                                    <button
                                        v-if="pagination.current_page > 3"
                                        @click="fetchPage(1)"
                                        class="rounded-full">
                                        1
                                    </button>

                                    <!-- Left Ellipsis -->
                                    <span v-if="pagination.current_page > 4">...</span>

                                    <!-- Page Buttons -->
                                    <button
                                        v-for="page in visiblePages"
                                        :key="page"
                                        @click="fetchPage(page)"
                                        :class="[
                                            { 'active bg-red-400 text-white px-2': page === pagination.current_page }
                                        ]"
                                        class="rounded-full">
                                        {{ page }}
                                    </button>

                                    <!-- Right Ellipsis -->
                                    <span v-if="pagination.current_page < pagination.last_page - 3">...</span>

                                    <!-- Last Page Button -->
                                    <button
                                        v-if="pagination.current_page < pagination.last_page - 2"
                                        @click="fetchPage(pagination.last_page)"
                                        class="rounded-full">
                                        {{ pagination.last_page }}
                                    </button>

                                    <!-- Next Button -->
                                    <button
                                        v-if="pagination.current_page < pagination.last_page"
                                        @click="fetchPage(pagination.current_page + 1)"
                                        class="rounded-full">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>

        <OnScroll />
        <Transition>
            <BottomDrawer v-show="bottom_drawer" class="block lg:hidden close-drawer" :class="{'show-drawer' : bottom_drawer}">
                <div class="gird grid-cols-1 h-full overflow-scroll">
                    <Icon 
                        icon="mdi:close-thick"
                        class="text-[1.5rem] mb-3"
                        @click="bottom_drawer = false"
                    />

                    <Filters class="flex lg:hidden flex-col" :category="filter_category" :sizes="sizes" @category_id="handleSelectedCategory"/>
                </div>
            </BottomDrawer>
        </Transition>
    </section>
</template>

<script>
import search from '@/mixins/search';
export default {
    mixins: [search]
}
</script>

<style scoped>
.pagination button.active {
    font-weight: bold;
}

.pagination button {
    margin: 0 5px;
}

.pagination span {
    margin: 0 10px;
}
</style>