import Axios from 'axios';
import store from '@/store';

// USE THE CONFIGURED HEADER ABOVE
const axios = Axios.create({
    baseURL: "https://wrightstendermeats.com.au/api/public/index.php/api",
    timeout: 60000,
    withCredentials: true,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    headers: {
        'Request-Form'   : 'Client-Panel',
        'Content-Type'   : 'application/json',
        'Accept'         : 'application/json',
    },
});

// Add a request interceptor to set the Authorization header
axios.interceptors.request.use(config => {
    const token = store.state.auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },error => {
        return Promise.reject(error);
    }
);

/**
 * CREATE INTERCEPTOR RESPONSE ERROR
 * BASICLY USE FOR CATCHING ERRORS WITH SOME PORT LIKE:
 * 400,
 * 401,
 * 422,
 * 403
 */
axios.interceptors.response.use(null, (err)=>{
    const error = {
        status      : err.response?.status,
        original    : err,
        validation  : {},
        message     : null
    }

    switch(err.response?.status) {
        case 422:
            for(let field in err.response.data.errors) {
                error.validation[field] = err.response.data.errors[field];
                error.message           = err.response.data.message;
            }
            break;
        case 401: 
            error.message = err.response.message;
            break;
        case 417: 
            error.message = err.response.message;
            break;
        case 500: 
            error.message = 'Something went wrong, Please try again.';
            break
    }

    return Promise.reject(error);
});

export default axios;

