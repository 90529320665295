<template>
    <div>
        <section class="bg-gray-100">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div class="max-w-3xl">
                    <h2 class="text-3xl font-bold sm:text-4xl">
                        About Us
                    </h2>
                </div>
            
                <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                    <div class="lg:py-16">
                        <article class="space-y-4 text-gray-600">
                            <p>
                                At Wright's Tender Meats, we are passionate about providing the finest quality meats to our community. Our family-owned and operated butcher shop has been a trusted source for premium cuts and exceptional service for over 3 years.
                                <br>
                                Our Commitment to Quality
                                <br>
                                Our commitment to quality begins with the careful selection of our meats. We work closely with local farmers and suppliers to source only the freshest and most ethically-raised animals. Every cut is hand-selected by our skilled butchers, ensuring that you receive nothing but the best. We take pride in offering a diverse range of meats, including succulent steaks, tender poultry, gourmet sausages, and more, all expertly prepared to meet your culinary needs.
                                <br>
                                Expert Butchers, Exceptional Service
                                <br>
                                Our team of expert butchers is at the heart of what we do. With years of experience and a deep knowledge of their craft, they are always on hand to provide advice, answer your questions, and assist you in selecting the perfect cut for any occasion. Whether you're planning a special family dinner or a weekend barbecue, our friendly and knowledgeable staff is here to make your experience a delight.
                            </p>
                        </article>
                    </div>

                    <div class="relative h-64 overflow-hidden sm:h-80 lg:h-full">
                        <img
                            alt=""
                            :src="$static_site('/aboutus_slider_gallery/slider-1.jpg')"
                            class="absolute inset-0 h-full w-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
                    <div class="relative z-10 lg:py-16">
                        <div class="relative h-64 sm:h-80 lg:h-full">
                            <img
                            alt=""
                            :src="$static_site('/aboutus_slider_gallery/slider-4.jpg')"
                            class="absolute inset-0 h-full w-full object-scale-down"
                            />
                        </div>
                    </div>
            
                    <div class="relative flex items-center bg-gray-100">
                        <span
                            class="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100"
                        ></span>
            
                        <div class="p-8 sm:p-16 lg:p-24">
                            <h2 class="text-2xl font-bold sm:text-3xl">
                                About the Company
                            </h2>
                
                            <p class="mt-4 text-gray-600">
                                Community Focus
                                <br>
                                Wright's Tender Meats is more than just a place to purchase meat; we're an integral part of the community. We believe in supporting our neighbors and giving back. We actively participate in local events and charitable endeavors to ensure our community thrives.
                                <br>
                                A Tradition of Excellence
                                <br>
                                For generations, the Wright family has been dedicated to providing the finest meats with a commitment to excellence. Our tradition is one of quality, integrity, and the pursuit of perfection in every aspect of our business.
                                <br>
                                Visit Us Today
                                <br>
                                We invite you to come and experience the difference at Wright's Tender Meats. Our butcher shop is more than just a store; it's a place where quality, tradition, and community come together. We look forward to serving you and helping you create delicious meals for your family and friends. Thank you for choosing Wright's Tender Meats for your meat needs.
                                <br>
                                Discover the difference in taste and service at Wright's Tender Meats. Your satisfaction is our priority, and we can't wait to serve you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-gray-100" id="contact">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="text-center">
                    <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">Contact As</h2>

                    <div class="flex items-center justify-center my-4">
                        <span class="flex items-center text-base me-2">
                            <Icon icon="mdi:phone" class="me-1"/>02 9629 9333
                        </span>
                        <span class="flex items-center text-base me-2">
                            <Icon icon="material-symbols:mail" class="me-1"/>
                            wrightstendermeat@gmail.com
                        </span>
                    </div>
                </div>
            </div>
        </section>

        <OnScroll />
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import OnScroll from '@/components/common/OnScroll.vue';
export default {
    components: {
        Icon,
        OnScroll
    }
}
</script>