import axios from "../plugins/axios"
import { Icon } from "@iconify/vue"

export default {
    components: {
        Icon
    },
    data(){
        return {
            most_viewed_product : null,
            most_sell_product   : null
        }
    },
    methods: {
        async getMostSellProducts() {
            await axios.get('/products/top-sell').then(res=>{
                if(res.data.code == 200) {
                    this.most_sell_product = res.data.data;
                }
            });
        },
        async getMostViewProducts() {
            await axios.get("/products/top-view").then(res=>{
                if(res.data.code == 200) {
                    this.most_viewed_product = res.data.data;
                }
            });
        }
    },
    mounted(){
        this.getMostViewProducts();
        this.getMostSellProducts();
    }
}