<template>
    <div class="fixed z-10 right-0 top-0 w-full md:w-[500px] h-[100vh] overflow-y-scroll px-10 py-8 shadow-lg bg-white">
        <div class="flex items-center justify-between">
            <h1 class="text-lg md:text-xl font-bold">Review</h1>
            <Icon icon="mdi:close" class="size-6" @click="$emit('close', false)"/>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
export default {
    components: {
        Icon
    }
}
</script>