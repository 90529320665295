<template>
    <section class="mx-auto max-w-screen-xl h-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="my-5 grid gap-6 lg:grid-cols-2 grid-cols-1">
            <!-- Product Image Section -->
            <div class="overflow-hidden">
                <div class="relative grid gap-4 lg:grid-cols-[0.4fr_1fr]">
                    <div class="grid gap-2">
                        <div class="hidden lg:block relative w-full h-0 pb-[100%] hover:shadow overflow-hidden border border-1 border-gray-500 rounded-lg">
                            <img 
                                :src="$static_site(product.product_image)" 
                                class="absolute top-0 left-0 w-full h-full rounded-lg object-cover" 
                                alt="Product Image"
                            >
                        </div>
                    </div>
                    <div>
                        <img :src="$static_site(product.product_image)" class="rounded-lg w-full h-[450px] object-cover" alt="Product Image">
                    </div>
                </div>
            </div>
            <!-- Additional Product Details or Other Content Here -->
            <div>
                <h1 class="text-2xl mb-2 lg:text-4xl font-bold text-gray-700 uppercase">{{ product.product_name }}</h1>
                <ul>
                    <div class="flex justify-start gap-0.5 text-yellow-500">
                        <div v-for="(star, index) in 5" :key="index">
                            <div v-if="index != parseInt(product.ratings) && product.ratings != null ">
                                <Icon icon="mdi:star" />
                            </div>
                            <div v-else>
                                <Icon icon="mdi:star-outline" />
                            </div>
                        </div>
                    </div>
                </ul>
                <p class="my-3 text-xl lg:text-2xl font-semibold text-gray-700">
                    ${{ product.product_current_price }} 
                    <span v-if="product.product_old_price" class="text-gray-400 line-through">
                        ${{ product.product_old_price }}
                    </span> 
                    <span v-if="product.product_old_price" class="ms-2 text-xs text-gray-300 font-normal bg-red-500 p-1 rounded">{{ $discount_percentage(product.product_old_price, product.product_current_price) }}%</span>
                </p>
                <p class="my-3 pb-3 border-b text-xs lg:text-sm text-gray-400" v-html="product.product_description">
                </p>
                <div class="grid grid-cols-[0.5fr_1fr] gap-2">
                    <div class="flex justify-between items-center rounded border border-gray-200">
                        <button type="button" @click="cart_detail.quantity--" class="size-10 leading-10 text-gray-600 transition hover:opacity-75">
                            &minus;
                        </button>
                    
                        <input
                            v-model="cart_detail.quantity"
                            type="number"
                            id="Quantity"
                            value="1"
                            class="h-10 w-full border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                        />
                    
                        <button type="button" @click="cart_detail.quantity++" class="size-10 leading-10 text-gray-600 transition hover:opacity-75">
                            &plus;
                        </button>
                    </div>
                    <button
                        class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-center text-white shadow hover:bg-red-700 focus:outline-none active:bg-red-500 sm:w-auto"
                        type="button"
                        @click="addToCartHandler(product.product_id)"
                        :disabled="loading==true"
                    >
                        <span v-if="loading==true">Loading...</span>
                        <span v-else>Add to Cart</span>
                    </button>
                </div>
            </div>
        </div>
        
        <div class="mt-10">
            <div class="sm:hidden">
                <label for="Tab" class="sr-only">Tab</label>
            
                <select id="Tab" class="w-full rounded-md border-gray-200">
                    <option selected>Ratings & Reviews</option>
                </select>
            </div>
        
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex gap-6" aria-label="Tabs">
                        <a
                            href="javascript:;"
                            class="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            aria-current="page"
                        >
                            Ratings & Reviews
                        </a>
                    </nav>
                </div>
            </div>

            <div class="relative mt-5 w-full" :class="{'overflow-y-scroll h-[50vh]' : reviews.length > 4}">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-2" v-if="reviews.length">
                    <div v-for="(data,index) in reviews" :key="index" class="border border-gray-200 rounded hover:shadow-sm p-4">
                        <ul class="mb-2">
                            <li class="flex text-lg md:text-xl text-yellow-500">
                                <Icon icon="mdi:star" />
                                <Icon icon="mdi:star" />
                                <Icon icon="mdi:star" />
                                <Icon icon="mdi:star" />
                                <Icon icon="mdi:star" />
                            </li>
                        </ul>
                        <h1 class="text-lg md:text-xl flex items-center">
                            {{ data.customer_name }}
                            <span class="text-lg md:text-xl ms-2 text-blue-700"><Icon icon="mdi:verified" /></span>
                        </h1>
                        <p class="mt-2 text-gray-500 w-full h-[100px] line-clamp-4">"{{ data.comment }}"</p>
                        <p class="mt-2 text-base md:text-lg text-gray-700 font-semibold">2 days ago</p>
                    </div>
                </div>
                <div v-else class="flex justify-center items-center py-5">
                    <h1 class="text-xl md:text-xl font-semibold text-gray-700">No Reviews Yet!</h1>
                </div>

                <div class="mb-5 flex justify-center items-center">

                </div>
                <div class="my-5 flex justify-center items-center" v-if="reviews.length" >
                    <a
                        class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none active:bg-red-500 sm:w-auto"
                        href="javascript:;"
                    >
                        Show More
                    </a>
                </div>
            </div>

            <div class="mt-20">
                <div class="mb-7 text-center">
                    <h1 class="text-2xl lg:text-4xl text-gray-700 font-bold">YOU MIGHT ALSO LIKE</h1>
                </div>
                <div class="grid gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                    <div v-for="(data,index) in most_sell_product" :key="index">
                        <a href="#" class="group relative block overflow-hidden hover:shadow">
                            <img
                                :src="$static_site(data.p_featured_photo)"
                                alt=""
                                class="h-64 w-full object-cover rounded-lg transition duration-500 group-hover:scale-105 sm:h-72"
                            />
                        
                            <div class="relative p-6">
                                <h3 class="mt-4 text-lg font-medium text-gray-900">{{ data.p_name }}</h3>
                                <ul>
                                    <li class="flex text-yellow-500">
                                        <Icon icon="mdi:star" />
                                        <Icon icon="mdi:star" />
                                        <Icon icon="mdi:star" />
                                        <Icon icon="mdi:star" />
                                        <Icon icon="mdi:star" />
                                    </li>
                                </ul>
                                <p class="mt-1.5 text-lg font-bold text-gray-700">${{ data.p_current_price }} </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <OnScroll />
        <Toast v-if="show_toast" type="success" message="Successfully added to cart" @closetoast="closeToast"/>
        <Toast v-if="show_toast_login" type="error" message="Please log in" @closetoast="closeToast"/>
    </section>
</template>

<script>
import Reviews from '@/components/common/Reviews.vue';
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import { mapGetters, mapActions } from 'vuex';
import OnScroll from '@/components/common/OnScroll.vue';
import Toast from '../components/common/Toast.vue';
import auth from '@/store/auth';

export default {
    components: {
        Icon,
        OnScroll,
        Toast
    },
    data() {
        return {
            product             : [],
            reviews             : [],
            most_sell_product   : null,
            detail              : {
                product_size: null
            },
            cart_detail         : {
                product_id  : null,
                quantity    : 1,
                user_id     : null
            },
            show_toast      : false,
            show_toast_login : false,
            loading          : false,
        };
    },
    computed: {
        ...mapGetters({
            auth_user: 'auth/auth_user'
        })
    },
    methods: {
        ...mapActions({
            addCart: 'cart/addCart',
            getCarts: 'cart/getUserCarts'
        }),
        getProductDetail() {
            axios.get(`/products/detail/${this.$route.params.id}`).then(res => {
                this.product = res.data.data;
            });
        },
        getProductRatingsAndReviews() {
            axios.get(`/products/reviews/${this.$route.params.id}`).then(res => {
                this.reviews = res.data.data;
            })
        },
        async getMostSellProducts() {
            await axios.get('/products/top-sell').then(res=>{
                if(res.data.code == 200) {
                    this.most_sell_product = res.data.data;
                }
            });
        },
        async addToCartHandler(product_id) {
            this.loading = true; // Set loading to true at the start of the function

            if (!this.auth_user) {
                this.show_toast_login = true;
                this.loading = false; // Ensure loading is reset if the user is not logged in
                return;
            }

            this.cart_detail.product_id = product_id;
            this.cart_detail.user_id = this.auth_user.cust_id;

            try {
                await this.addCart(this.cart_detail); // Wait for addCart to finish
                await this.getCarts(this.auth_user.cust_id); // Wait for getCarts to finish

                this.show_toast = true;
            } catch (error) {
                console.error("Error adding to cart:", error);
                // You can handle any error logic here if necessary
            } finally {
                this.loading = false; // Reset loading after everything is done
            }
        },
        closeToast(data) {
            this.show_toast         = data;
            this.show_toast_login   = data;
        },
    },
    mounted() {
        this.getProductDetail();
        this.getProductRatingsAndReviews();
        this.getMostSellProducts();
        this.show_toast = false;
    }
};
</script>
