<template>
    <div class="mx-auto max-w-screen-xl h-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="grid gap-3 grid-cols-1 md:grid-cols-[1fr_0.5fr]">
            <div class="p-3 border rounded">
                <div class="my-2 py-2 border-b">
                    <h1 class="text-lg md:text-xl font-bold">Order Details</h1>
                </div>
                <div class="my-2 px-5">
                    <div v-for="(data, index) in details.carts" :key="index" class="my-2 py-5 border-b">
                        <div class="w-full flex justify-between">
                            <div>
                                <div class="flex items-center gap-3">
                                    <img :src="$static_site(data.product_image)" class="w-[70px] md:w-[90px] p-2 bg-gray-100 rounded shadow" alt="">
                                    <div>
                                        <span class="text-lg md:text-xl font-bold">{{ data.product_name }}</span><br>
                                        <span class="text-xs md:text-sm text-gray-600 font-semibold">Quantity: <span class="text-black">{{ data.quantity }}</span></span><br>
                                        <span class="text-xs md:text-sm text-gray-600 font-semibold">Category: <span class="text-black">{{ data.category_name }}</span></span>
                                    </div>
                                </div>

                                <div class="my-4 flex justify-center w-full" v-if="data.is_reviewed == 0 && details.status == 'completed'">
                                    <button
                                        type="button"
                                        @click="show_canvas = true; setProductId(details.id, data.cart_id)"
                                        class="inline-block rounded bg-red-600 px-3 py-1 text-sm font-medium text-white"
                                    >
                                        Review
                                    </button>
                                </div>
                            </div>
                            <span class="font-bold text-xl md:text-lg">${{ data.sub_total }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-3 border rounded h-[246px]">
                <div class="my-2 py-2 flex items-center justify-between border-b">
                    <h1 class="text-lg md:text-xl font-bold">Order Summary</h1>
                    <a :href="'http://127.0.0.1:8000/invoice?order_master_id='+details.id" target="_blank">
                        <Icon icon="mdi:printer-outline" class="size-5"/>
                    </a>
                </div>
                <div class="my-2">
                    <div class="my-2 flex items-center justify-between">
                        <span class="text-xs md:text-sm font-semibold text-gray-500">Payment Status: <span>Success</span></span>
                    </div>
                    <div class="my-2 flex items-center justify-between">
                        <span class="text-xs md:text-sm font-semibold text-gray-500">Payment Information: <span>Credit Card</span></span>
                    </div>
                    <div class="my-2 flex items-center justify-between">
                        <span class="text-xs md:text-sm font-semibold text-gray-500">Receive Type: <span>{{ details ? String(details.received_option).toUpperCase() : '' }}</span></span>
                    </div>
                    <div class="my-2 flex items-center justify-between">
                        <span class="text-xs md:text-sm font-semibold text-gray-500">Status: <span>{{ details ? String(details.status).toUpperCase() : '' }}</span></span>
                    </div>
                    <div class="my-2 py-2 border-t flex items-center justify-between">
                        <span class="text-base font-semibold ">Total Cost: <span>${{ parseFloat(details.total_cost).toFixed(2) }}</span></span>
                    </div>
                </div>
            </div>
        </div>


        <Transition>
            <ReviewProduct v-show="show_canvas" class="close-canvas" :class="{'show-canvas' : show_canvas}" @close="(val)=>{show_canvas = val}">
                <div>
                    <div class="my-4">
                        <div class="my-2">
                            <h1 class="font-semibold text-lg md:text-xl">Select Star</h1>
                        </div>
                        <div class="flex">
                            <svg
                            v-for="star in 5"
                            :key="star"
                            @mouseover="setRating(star)"
                            @mouseleave="resetRating"
                            @click="setRating(star)"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-8 w-8 cursor-pointer transition-colors duration-200"
                            :class="rating >= star ? 'text-yellow-500' : 'text-gray-300'"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l2.385 7.348h7.748c.969 0 1.371 1.24.588 1.81l-6.28 4.56 2.385 7.348c.3.921-.755 1.688-1.541 1.136L12 18.414l-6.377 4.623c-.786.552-1.84-.215-1.541-1.136l2.385-7.348-6.28-4.56c-.783-.57-.381-1.81.588-1.81h7.748l2.385-7.348z"
                            />
                            </svg>
                        </div>
                    </div>
                    <div class="my-4">
                        <div class="my-2">
                            <h1 class="font-semibold text-lg md:text-xl">Write Comment</h1>
                        </div>
                        <label class="sr-only" for="message">Message</label>
                        <textarea
                            class="w-full rounded outline-black border border-gray-200 shadow-sm p-3 text-sm"
                            placeholder="Message"
                            rows="8"
                            id="message"
                            v-model="review"
                            required
                        ></textarea>
                        <span class="text-xs md:text-sm text-red-400" v-if="msg">{{ msg }}</span>
                    </div>
                    <div class="my-4">
                        <button
                            @click="submitReviewHandler"
                            type="button"
                            class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
                        >
                            Submit
                        </button>    
                    </div>
                </div>
            </ReviewProduct>
        </Transition>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import ReviewProduct from '@/components/common/ReviewProduct.vue';

export default {
    components: {
        Icon,
        ReviewProduct
    },
    data() {
        return {
            loading: false,
            details: [],
            rating: 0,
            show_canvas: false,
            product_id: null,
            review: null,
            msg: null,
            cart_id: null
        }
    },
    methods: {
        setRating(value) {
            this.rating = value;
        },
        resetRating() {
            this.rating = this.rating; // Keeps the rating when mouse leaves
        },
        setProductId(id, cart_id) {
            this.product_id = id;
            this.cart_id = cart_id;
        },
        submitReviewHandler() {
            this.msg = null;
            if(this.review == null) {
                this.msg = "Text is empty";
                return;
            }

            this.loading = true;
            
            const form = {
                ratings: this.rating,
                product_id: this.product_id,
                review: this.review,
                auth_id: this.$store.state.auth.auth_user.cust_id,
                cart_id: this.cart_id
            }

            axios.post('/ratings/add-review', form).then(res=>{
                this.getDetails();
                this.show_canvas = false;
            })
            .catch(err=>{})

            this.loading = false;
        },
        getDetails() {
            axios.get(`/carts/my-orders/detail/${this.$route.params.id}`).then(res=>{
                if(res.status == 200) {
                    this.details = res.data.data
                }
            })
            .catch(err=>{
            })
        }
    },
    mounted() {
        this.getDetails();
    }
}
</script>
<style>
/* Optional: Adding a default color for unselected stars */
.text-gray-300 {
  color: #d1d5db; /* Tailwind's gray-300 color */
}
.text-yellow-500 {
  color: #f59e0b; /* Tailwind's yellow-500 color */
}
</style>