import { createStore } from 'vuex'
import cart from './cart'
import auth from './auth'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  modules: {
    cart,
    auth:auth
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
