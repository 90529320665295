<template>
    <div 
        class="fixed end-[10px] bottom-[50px] rounded shadow p-4 flex justify-between items-center w-[300px] text-white"
        :class="[
            {'bg-green-600' : type == 'success'},
            {'bg-red-600'   : type == 'error'},
            {'bg-yello-600' : type == 'warning'}
        ]"
    >
        <span class="flex items-center">
            <Icon icon="mdi:check-circle-outline" v-if="type == 'success'"/>
            <Icon icon="mdi:close" v-if="type == 'error'"/>
            <Icon icon="mdi:warning" v-if="type == 'warning'"/>
            {{ message }}
        </span>
        <span @click="$emit('closetoast', false);" class="cursor-pointer">
            <Icon icon="mdi:close"/>
        </span>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
    props: [
        'message',
        'type'
    ],
    components: {
        Icon
    }
}
</script>

<style>

</style>