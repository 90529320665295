<template>
    <section class="bg-no-repeat bg-cover bg-center bg-[url('https://images5.alphacoders.com/128/1288016.jpg')]">
      <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
        <div class="mx-auto max-w-xl text-center">
          <h1 class="text-3xl font-extrabold sm:text-5xl text-white">
            Best Meat In Town.
            <strong class="font-extrabold text-red-700 sm:block"> Badget Price Meats. </strong>
          </h1>
    
          <p class="mt-4 sm:text-xl/relaxed text-white">
            Wright's Tender Meat is an online butcher shop dedicated to providing high-quality meats and authentic Filipino food.
          </p>
    
          <div class="mt-8 flex flex-wrap justify-center gap-4">
            <router-link to="/meats"
              class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
              href="javascript:;"
            >
                Shop Now
            </router-link>
    
            <router-link
              to="/about-us"
              class="block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto border border-1 border-white"
              href="#"
            >
              See Us More
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-gray-100">
        <header class="text-center">
            <h2 class="text-xl font-bold text-gray-900 sm:text-3xl pt-8 sm:pt-12">Top Seller Meats</h2>
            <p class="mx-auto mt-4 max-w-md text-gray-500">
              Shop our top sellers today and experience the quality and taste that make Wright's Tender Meat a preferred choice for meat lovers!
            </p>
        </header>

        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
            <div class="mt-8 grid gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                <div v-for="(data,index) in most_sell_product" :key="index">
                    <router-link :to="{name: 'detailMeatPage', params: {id: data.p_id}}" class="group relative block overflow-hidden hover:shadow">
                        <img
                          :src="$static_site(data.p_featured_photo)"
                          alt=""
                          class="h-64 w-full object-cover rounded-lg transition duration-500 group-hover:scale-105 sm:h-72"
                        />
                    
                        <div class="relative p-6">
                            <h3 class="mt-4 text-lg font-medium text-gray-900">{{ data.p_name }}</h3>
                            <ul>
                              <div class="flex justify-start gap-0.5 text-yellow-500">
                                  <div v-for="(star, index) in 5" :key="index">
                                      <div v-if="index != parseInt(data.ratings) && data.ratings != null ">
                                          <Icon icon="mdi:star" />
                                      </div>
                                      <div v-else>
                                          <Icon icon="mdi:star-outline" />
                                      </div>
                                  </div>
                              </div>
                            </ul>
                            <p class="mt-1.5 text-lg font-bold text-gray-700">${{ data.p_current_price }} </p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-10 lg:mt-16 w-full flex items-center justify-center">
              <router-link to="/meats"
                class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
              >
                Shop More
              </router-link>
            </div>
        </div>
    </section>

    <section>
        <header class="text-center">
            <h2 class="text-xl font-bold text-gray-900 sm:text-3xl pt-8 sm:pt-12">Most Viewed</h2>
            <p class="mx-auto mt-4 max-w-md text-gray-500">
              Check out our most viewed products and see why they are favorites among our customers at Wright's Tender Meat!
            </p>
        </header>

        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
            <div class="mt-8 grid gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                <div v-for="(data,index) in most_viewed_product" :key="index">
                    <router-link :to="{name: 'detailMeatPage', params: {id: data.p_id}}" class="group relative block overflow-hidden hover:shadow">
                        <img
                          :src="$static_site(data.p_featured_photo)"
                          alt=""
                          class="h-64 w-full rounded-lg object-cover transition duration-500 group-hover:scale-105 sm:h-72"
                        />
                    
                        <div class="relative bg-white p-6">
                            <h3 class="mt-4 text-lg font-medium text-gray-900">{{ data.p_name }}</h3>
                            <ul>
                              <div class="flex justify-start gap-0.5 text-yellow-500">
                                  <div v-for="(star, index) in 5" :key="index">
                                      <div v-if="index != parseInt(data.ratings) && data.ratings != null ">
                                          <Icon icon="mdi:star" />
                                      </div>
                                      <div v-else>
                                          <Icon icon="mdi:star-outline" />
                                      </div>
                                  </div>
                              </div>
                            </ul>
                            <p class="mt-1.5 text-lg font-bold text-gray-700">${{ data.p_current_price }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-10 lg:mt-16 w-full flex items-center justify-center">
              <router-link to="/meats"
                class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
              >
                View More
              </router-link>
            </div>
        </div>
    </section>

    <section class="bg-gray-100">
    <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
      <header class="text-center">
        <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">Browse By Meat</h2>
  
        <p class="mx-auto mt-4 max-w-md text-gray-500">
          Browse the best meats in different cuts and preparations for your next meal.
        </p>
      </header>
  
      <ul class="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
        <li>
          <a href="#" class="group relative block">
            <img
              :src="$static_site('browse-1.jpeg')"
              alt=""
              class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
            />
  
            <div class="absolute inset-0 flex flex-col items-start justify-end p-6">
              <h3 class="text-xl font-medium text-white">Ribs</h3>
  
              <router-link to="/meats"
                class="rounded mt-1.5 inline-block bg-red-600 hover:bg-red-700 px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
              >
                Shop Now
              </router-link>
            </div>
          </a>
        </li>
  
        <li>
          <a href="#" class="group relative block">
            <img
              :src="$static_site('browse-2.jpeg')"
              alt=""
              class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
            />
  
            <div class="absolute inset-0 flex flex-col items-start justify-end p-6">
              <h3 class="text-xl font-medium text-white">Parts</h3>
  
              <router-link to="/meats"
                class="rounded mt-1.5 inline-block bg-red-600 hover:bg-red-700 px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
              >
                Shop Now
              </router-link>
            </div>
          </a>
        </li>
  
        <li class="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
          <a href="#" class="group relative block">
            <img
              :src="$static_site('browse-3.jpg')"
              alt=""
              class="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
            />
  
            <div class="absolute inset-0 flex flex-col items-start justify-end p-6">
              <h3 class="text-xl font-medium text-white">Cuts</h3>
  
              <router-link to="/meats"
                class="rounded mt-1.5 inline-block bg-red-600 hover:bg-red-700 px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
              >
                Shop Now
              </router-link>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </section>

  <Reviews />
  <OnScroll />
</template>

<script>
import Reviews from '@/components/common/Reviews.vue';
import home from '@/mixins/home';
import OnScroll from '@/components/common/OnScroll.vue';

export default {
    components: {
        Reviews,
        OnScroll
    },
    mixins: [
        home
    ]
}
</script>