<template>
  <section class="relative flex flex-wrap lg:h-screen lg:items-center">
    <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
      <div class="mx-auto max-w-lg text-center">
        <h1 class="text-2xl font-bold sm:text-3xl">Buy your meat today!</h1>
  
        <p class="mt-4 text-gray-500">
          Purchase your fresh, high-quality meat today and savor delicious flavors in every bite!
        </p>
      </div>
  
      <form @submit.prevent="submitLoginHandler" class="mx-auto mb-0 mt-8 max-w-md space-y-4">
        <div>
          <div class="relative">
            <label
              class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
            >
              <input
                v-model="form.email"
                type="email"
                class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                placeholder="Username"
              />
            
              <span
                class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
              >
                Email
              </span>
            </label>
          </div>
        </div>
  
        <div>
          <div class="relative">
            <label
              class="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-black focus-within:ring-1 focus-within:ring-black"
            >
              <input
                v-model="form.password"
                type="password"
                class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                placeholder="Username"
              />
            
              <span
                class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
              >
                Password
              </span>
            </label>
          </div>

          <div class="my-2" v-if="login_msg">
            <span class="text-red-500">{{ login_msg }}</span>
          </div>
        </div>
  
        <div class="flex items-center justify-between">
          <p class="text-sm text-gray-500">
            No account?
            <router-link to="/register" class="underline">
              Register
            </router-link>
            or 
            <router-link to="/" class="underline">
              Back to Home
            </router-link>
          </p>
  
          <button
            type="submit"
            class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
            :disabled="loading"
          >
            <span v-if="!loading">Sign In</span>
            <span v-else>Loading...</span>
          </button>
        </div>
      </form>
    </div>
  
    <div class="relative h-64 w-full sm:h-96 lg:h-full lg:w-1/2">
      <img
        alt=""
        :src="$static_site('/aboutus_slider_gallery/slider-0.jpg')"
        class="absolute inset-0 h-full w-full object-cover"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '../plugins/axios';

  export default {
    data() {
      return {
        form: {
          email     : null,
          password  : null
        },
        login_flag  : null,
        login_msg   : null,
        loading     : false
      }
    },
    methods: {
      ...mapActions({
        setAuthUser: 'auth/setAuthUser'
      }),
      submitLoginHandler() {
        this.login_msg = null;
        this.loading = true;
        axios.post('/customer/login', this.form).then((res)=>{
          if(this.setAuthUser(res)) {
            this.$router.push('/');
            this.loading = false;
          }
        }).catch((err)=>{
          this.login_msg = "Something went wrong. Check Email or Password."
          this.loading = false;
        });
        this.loading = false;
      }
    }
  }
</script>