const state = {
    token           : null,
    auth_user       : null,
    is_logged_id    : false
}
const getters = {
    auth_user       : state => state.auth_user,
    is_logged_id    : state => state.is_logged_id
}
const mutations = {
    SET_AUTH_USER(state, auth_user) {
        state.auth_user     = auth_user;
        state.is_logged_id  = true;
    },
    SET_TOKEN(state, token) {
        state.token = token
    },
    LOGOUT_AUTH_USER(state) {
        state.auth_user     = null;
        state.token         = null;
        state.is_logged_id  = false;
    }
}
const actions = {
    setAuthUser({commit}, params) {
        commit("SET_AUTH_USER", params.data.data.user);
        commit("SET_TOKEN", params.data.data.token);

        return true;
    },
    logoutAuthUser({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit("LOGOUT_AUTH_USER");
                localStorage.removeItem('auth');
                resolve(true);
            } catch(err) {
                reject(err);
            }
        });
    }
}

export default {
    namespaced: true,
    state,  
    getters,
    mutations,
    actions
};