<template>
    <div>
        <div v-if="!exclude_links.includes($route.path)">
            <Navbar/>
        </div>
        <router-view />
        <div v-if="!exclude_links.includes($route.path)">
            <Footer />
        </div>
    </div>
</template>

<script>
import Navbar from './components/layout/Navbar.vue';
import Footer from './components/layout/Footer.vue';
import search from '@/mixins/search';
export default {
    mixins: [
        search
    ],
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            exclude_links: [
                '/sign-in',
                '/register'
            ]
        }
    }
}
</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>