import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignInView from '@/views/SignInView.vue'
import AboutUsView from '@/views/AboutUsView.vue'
import OurShopView from '@/views/OurShopView.vue'
import CartsView from '@/views/CartsView.vue'
import MeatsView from '@/views/MeatsView.vue'
import DetailMeatView from '@/views/DetailMeatView.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import store from '@/store'
import MyOrdersView from '@/views/MyOrdersView.vue'
import ProfileView from '@/views/ProfileView.vue'
import InvoiceView from '@/views/InvoiceView.vue'
import DetailsView from '@/views/DetailsView.vue'
import RegisterView from '@/views/RegisterView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'about',
    component: AboutUsView
  },
  {
    path: '/our-shop',
    name: 'ourShop',
    component: OurShopView
  },
  {
    path: '/cart-page',
    name: 'cartPage',
    component: CartsView,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/meats',
    name: 'meatPage',
    component: MeatsView
  },
  {
    path: '/meats/detail/:id',
    name: 'detailMeatPage',
    component: DetailMeatView
  },
  {
    path: '/checkout',
    name: 'checkoutPage',
    component: CheckoutView,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/sign-in',
    name: 'login',
    component: SignInView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/my-orders',
    name: 'myOrders',
    component: MyOrdersView,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: InvoiceView,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/order/detail/:id',
    name: 'orderDetail',
    component: DetailsView,
    meta: {
      requiredAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { top: 0 };
  }
})

// Guard Routes
router.beforeEach((to, from, next) => {
  if(to.meta.requiredAuth) {
    if(!store.state.auth.is_logged_id && !store.state.auth.token) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
