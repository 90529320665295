<template>
    <div class="mx-auto max-w-screen-xl h-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="pb-4 flex flex-col md:flex-row justify-between items-center border-b">
            <div class="flex items-center flex-col md:flex-row">
                <div class="size-14 md:size-20 rounded-full object-cover flex justify-center items-center bg-red-300">
                    <span class="text-xl md:text-3xl font-bold text-gray-200">{{ getInitials(user_detail.fullname)}}</span>
                </div>
                <div class="ms-4 md:text-start text-center">
                    <span class="text-lg md:text-xl font-bold text-gray-800">{{ user_detail.fullname }}</span>
                    <br>
                    <span class="md:text-lg font-semibold text-gray-400">Customer</span>
                </div>
            </div>

            <div class="flex gap-4 items-center mt-4 md:mt-0">
                <div class="flex gap-2 items-center">
                    <Icon icon="mdi:basket-check" class="size-11 md:size-14 rounded-full bg-gray-200 p-2 text-red-300"/>
                    <div>
                        <p class="font-semibold text-gray-700 text-lg md:text-xl">50</p>
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Orders</p>
                    </div>
                </div>
                <div class="flex gap-2 items-center">
                    <Icon icon="mdi:money-100" class="size-11 md:size-14 rounded-full bg-gray-200 p-2 text-gray-500"/>
                    <div>
                        <p class="font-semibold text-gray-700 text-lg md:text-xl">$1150</p>
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Total Spent</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-2 grid gap-4 grid-cols-1 md:grid-cols-2">
            <div class="border p-3 rounded">
                <h1 class="font-semibold text-lg md:text-xl">Personal Details</h1>
                <div class="mt-3">
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Fullname</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.fullname }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Email</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.email }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Phone</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.phone}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Country</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.country}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Address</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.address }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">City</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.city }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">State</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.state }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Zip</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.code}}</p>
                    </div>  

                    <div class="my-4">
                        <a href="javascript:;" class="border border-red-400 text-red-400 px-3 p-1 rounded text-sm" @click="showCanvas('personal')">Edit</a>
                    </div>
                </div>
            </div>

            <div class="border p-3 rounded">
                <h1 class="font-semibold text-lg md:text-xl">Billing Details</h1>
                <div class="mt-3">
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Fullname</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.b_fullname }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Phone</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.b_phone}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Country</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.b_country}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Address</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.b_address }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">City</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.b_city }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">State</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.b_state }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Zip</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.b_code }}</p>
                    </div>  

                    <div class="my-4">
                        <a href="javascript:;" class="border border-red-400 text-red-400 px-3 p-1 rounded text-sm" @click="showCanvas('billing')">Edit</a>
                    </div>
                </div>
            </div>

            <div class="border p-3 rounded">
                <h1 class="font-semibold text-lg md:text-xl">Shipping Details</h1>
                <div class="mt-3">
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Fullname</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.s_fullname }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Phone</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.s_phone}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Country</p>
                        <p class="font-semibold text-xs md:text-sm">{{user_detail.s_country}}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Address</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.s_address }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">City</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.s_city }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">State</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.s_state }}</p>
                    </div>
                    <div class="my-2">
                        <p class="font-semibold text-xs md:text-sm text-gray-400">Zip</p>
                        <p class="font-semibold text-xs md:text-sm">{{ user_detail.s_code }}</p>
                    </div>   

                    <div class="my-4">
                        <a href="javascript:;" class="border border-red-400 text-red-400 px-3 p-1 rounded text-sm" @click="showCanvas('shipping')">Edit</a>
                    </div>
                </div>
            </div>
        </div>


        <Transition>
            <ProfileOffCanvas v-show="show_canvas" class="close-canvas" :class="{'show-canvas' : show_canvas}" :title="String(type).toUpperCase()" @close="closeCanvas">

                <div
                    v-if="message_type == 'success' || message_type == 'error'"
                    class="my-4 w-full rounded shadow p-4 flex justify-between items-center text-white"
                    :class="[
                        {'bg-green-600' : message_type == 'success'},
                        {'bg-red-600'   : message_type == 'error'},
                    ]"
                >
                    <span class="flex items-center">
                        <Icon icon="mdi:check-circle-outline" v-if="message_type == 'success'"/>
                        <Icon icon="mdi:close" v-if="message_type == 'error'"/>
                        {{ message }}
                    </span>
                    <span class="cursor-pointer">
                        <Icon icon="mdi:close" @click="message_type = null"/>
                    </span>
                </div>
                
                <div v-if="type == 'personal'">
                    <div class="my-5">
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    required
                                    v-model="user_detail.fullname"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Fullname
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    required
                                    v-model="user_detail.email"
                                    type="email"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Email
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.phone"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Phone
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.country"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Country
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.address"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Address
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.city"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    City
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.state"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    State
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.code"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Zip
                                </span>
                            </label>
                        </div>
                        <div class="my-4">
                            <button
                                type="button"
                                @click="updateHandler"
                                class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
                                :disabled="loading"
                            >
                                <span v-if="!loading">Submit</span>
                                <span v-else>Loading...</span>
                            </button>    
                        </div>
                    </div>
                </div>

                <div v-if="type == 'shipping'">
                    <div class="my-5">
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    required
                                    v-model="user_detail.s_fullname"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Fullname
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_phone"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Phone
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_country"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Country
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_address"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Address
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_city"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    City
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_state"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    State
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.s_code"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Zip
                                </span>
                            </label>
                        </div>
                        <div class="my-4">
                            <button
                                type="button"
                                @click="updateHandler"
                                class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
                                :disabled="loading"
                            >
                                <span v-if="!loading">Submit</span>
                                <span v-else>Loading...</span>
                            </button>    
                        </div>
                    </div>
                </div>

                <div v-if="type == 'billing'">
                    <div class="my-5">
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    required
                                    v-model="user_detail.b_fullname"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Fullname
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_phone"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Phone
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_country"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Country
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_address"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Address
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_city"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    City
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_state"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    State
                                </span>
                            </label>
                        </div>
                        <div class="my-4 relative">
                            <label
                                class="relative block rounded-md border border-gray-400 focus-within:border-black focus-within:ring-1 focus-within:ring-black">
                                <input
                                    v-model="user_detail.b_code"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Zip
                                </span>
                            </label>
                        </div>
                        <div class="my-4">
                            <button
                                type="button"
                                @click="updateHandler"
                                class="inline-block rounded bg-red-600 px-5 py-3 text-sm font-medium text-white"
                                :disabled="loading"
                            >
                                <span v-if="!loading">Submit</span>
                                <span v-else>Loading...</span>
                            </button>    
                        </div>
                    </div>
                </div>

            </ProfileOffCanvas>
        </Transition>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import ProfileOffCanvas from '@/components/common/ProfileOffCanvas.vue';

export default {
    components: {
        Icon,
        ProfileOffCanvas
    },
    data() {
        return {
            authenticated_user: null,
            user: null,
            user_detail: {
                fullname: null,
                email: null,
                cust_phone: null,
                cust_country: null,
                cust_address: null,
                cust_city: null,
                cust_state: null,
                cust_zip: null,
                cust_b_name: null,
                cust_b_phone: null,
                cust_b_country:null,
                cust_b_address:null,
                cust_b_city: null,
                cust_b_state: null,
                cust_b_zip: null,
                cust_s_name: null,
                cust_s_phone: null,
                cust_s_country:null,
                cust_s_address:null,
                cust_s_city: null,
                cust_s_state: null,
                cust_s_zip: null,
            },
            title: null,
            show_canvas: false,
            type: null,
            loading: false,
            message_type: null,
            message: null
        }
    },
    methods: {
        getUserDetail() {
            axios.get('/customer/'+this.authenticated_user.cust_id).then(res=>{
                this.user = res.data.data;

                this.user_detail.fullname           = this.user.cust_name;
                this.user_detail.email              = this.user.email;
                this.user_detail.phone         = this.user.cust_phone;
                this.user_detail.country       = this.user.cust_country;
                this.user_detail.address       = this.user.cust_address;
                this.user_detail.city          = this.user.cust_city;
                this.user_detail.state         = this.user.cust_state;
                this.user_detail.code           = this.user.cust_zip;

                this.user_detail.b_fullname           = this.user.cust_b_name;
                this.user_detail.b_phone          = this.user.cust_b_phone;
                this.user_detail.b_country        = this.user.cust_b_country;
                this.user_detail.b_address        = this.user.cust_b_address;
                this.user_detail.b_city           = this.user.cust_b_city;
                this.user_detail.b_state          = this.user.cust_b_state;
                this.user_detail.b_code            = this.user.cust_b_zip;

                this.user_detail.s_fullname           = this.user.cust_s_name;
                this.user_detail.s_phone          = this.user.cust_s_phone;
                this.user_detail.s_country        = this.user.cust_s_country;
                this.user_detail.s_address        = this.user.cust_s_address;
                this.user_detail.s_city           = this.user.cust_s_city;
                this.user_detail.s_state          = this.user.cust_s_state;
                this.user_detail.s_code          = this.user.cust_s_zip;

            })
            .catch(err=>{

            });
        },
        getInitials(phrase) {
            if (!phrase) return '';

            return phrase
                .split(' ')
                .map(word => word.charAt(0).toUpperCase())
                .join('');
        },
        showCanvas(type) {
            this.type = type;
            this.show_canvas = true;
        },
        closeCanvas(value) {
            this.show_canvas = value;
            this.type = null;
        },
        async updateHandler() {
            this.loading = true;

            await axios.post('/customer/update-profile', {
                data: this.user_detail,
                type: this.type,
                customer_id: this.authenticated_user.cust_id
            }).then(res=>{
                if(res.status == 201) {
                    this.message_type = 'success';
                    this.message = 'Detail has been updated.';
                }
            })
            .catch(err=>{
                this.loading = false
                this.message_type = 'error';
                this.message = 'Unable to update detail.';
            })

            this.loading = false;
        }
    },
    mounted() {
        this.authenticated_user = this.$store.state.auth.auth_user;

        this.getUserDetail();
    }
}
</script>

<style>

</style>