<template>
    <section class="bg-white">
        <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <h2 class="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Read trusted reviews from our customers
            </h2>
        
            <div class="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
                <blockquote class="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8" v-for="(rate, index) in customer_ratings" :key="index">
                    <div class="flex items-center gap-4">
                        <div class="size-14 rounded-full object-cover flex justify-center items-center bg-red-300">
                            <span class="md:text-lg font-semibold">{{ getInitials(rate.cust_name) }}</span>
                        </div>
                
                        <div>
                            <div class="flex justify-center gap-0.5 text-yellow-500">
                                <div v-for="(star, index) in 5" :key="index">
                                    <div v-if="index != rate.rating ">
                                        <Icon icon="mdi:star" />
                                    </div>
                                    <div v-else>
                                        <Icon icon="mdi:star-outline" />
                                    </div>
                                </div>
                            </div>
                
                            <p class="mt-0.5 text-lg font-medium text-gray-900">{{ rate.cust_name }}</p>
                        </div>
                    </div>
            
                    <p 
                        class="mt-4 text-gray-700 h-[250px] md:h-[350px] overflow-y-scroll" 
                        style="
                            scrollbar-width: none;
                        "
                    >
                        {{ rate.comment }}
                    </p>
                </blockquote>
            </div>
        </div>
    </section>
</template>

<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';

export default {
    components: {
        Icon
    },
    data() {
        return {
            customer_ratings: []
        }
    },
    methods: {
        async getTopCustomerRatings() {
            await axios.get('/ratings').then(res=>{
                if(res.data.code == 200) {
                    this.customer_ratings = res.data.data;
                }
            }).catch(err=>{})
        },
        getInitials(phrase) {
            if (!phrase) return '';

            return phrase
                .split(' ')
                .map(word => word.charAt(0).toUpperCase())
                .join('');
        }
    },
    mounted() {
        this.getTopCustomerRatings();
    }
}
</script>

