import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import './assets/custom.css'
import {static_site, logo_site} from './utils/static_site';
import discount_percentage from './utils/common';

const app = createApp(App);

app.config.globalProperties.$static_site = static_site;
app.config.globalProperties.$logo_site = logo_site;
app.config.globalProperties.$discount_percentage = discount_percentage;


app.use(store);
app.use(router);
app.mount('#app');
