<template>
    <section class="bg-gray-100">
      <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
        <div class="mx-auto max-w-3xl">
          <header class="text-center">
            <h1 class="text-xl font-bold text-gray-900 sm:text-3xl">Your Cart</h1>
          </header>
    
          <div class="mt-8">
            <div class="space-y-4" v-if="carts.length">
              <li v-for="(data,index) in user_cart" :key="index" class="flex items-center gap-4 my-2">
                <img
                    :src="$static_site(data.product_photo)"
                    alt=""
                    class="size-16 rounded object-cover"
                />
        
                <div>
                    <h3 class="text-sm text-gray-900">{{data.product_name}}</h3>
        
                    <dl class="mt-0.5 space-y-px text-[10px] text-gray-600">
                        <div>
                            <dt class="inline">Category:</dt>
                            <dd class="inline">{{ data.mid_category_name }}</dd>
                        </div>
            
                        <div>
                            <dt class="inline">Subtotal:</dt>
                            <dd class="inline">{{ data.sub_total }}</dd>
                        </div>
                        <div>
                            <dt class="inline">Quantity:</dt>
                            <dd class="inline">{{ data.quantity }}</dd>
                        </div>
                    </dl>
                </div>
        
                <div class="flex flex-1 items-center justify-end gap-2">
                    <button class="text-gray-600 transition hover:text-red-600" @click="deleteCartHandler(data.cart_id)">
                        <span class="sr-only">Remove item</span>
            
                        <Icon icon="mdi:trash" class="text-lg"/>
                    </button>
                </div>
              </li>
            </div>
            <div v-else class="flex justify-center items-center">
              <h1 class="text-xl md:text-2xl text-gray-600 font-semibold">No Products in Carts</h1>
            </div>
    
            <div class="mt-8 flex justify-end border-t border-gray-100 pt-8" v-if="carts.length">
              <div class="w-screen max-w-lg space-y-4">
                <dl class="space-y-0.5 text-sm text-gray-700">
    
                  <div class="flex justify-between !text-base font-medium">
                    <dt>Total</dt>
                    <dd>${{ sub_total }}</dd>
                  </div>
                </dl>
    
                <div class="flex justify-end">
                  <router-link to="/checkout"
                    href="#"
                    class="block rounded bg-red-600 px-5 py-3 text-sm text-white transition hover:bg-red-700"
                  >
                    Checkout
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toast v-if="flag" type="success" :message="flag_message" @closetoast="clostToast"/>
    </section>
  </template>


<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import { watch } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Toast from '@/components/common/Toast.vue';

export default {
    components: {
        Icon,
        Toast
    },
    data() {
        return {
            user_dropdown   : false,
            show_cart_modal : false,
            user_cart       : [],
            flag            : false,
            flag_message    : null,
            total           : 0

        }
    },
    watch: {
        carts(newCarts, oldCarts) {
            if(newCarts) {
                this.user_cart = newCarts;
            }
        },
        sub_total(newSubTotal, oldSubTotal) {
          return newSubTotal;
        }
    },
    computed: {
        ...mapGetters({
            carts       : 'cart/carts',
            sub_total   : 'cart/sub_total',
            auth        : 'auth/auth_user'
        })
    },
    methods: {
        ...mapActions({
            getCarts: 'cart/getUserCarts'
        }),
        deleteCartHandler(cart_id) {
            axios.delete(`/carts/delete/${cart_id}`).then(res=>{
                this.flag           = true;
                this.flag_message   = "Successfully Deleted!"
                this.getCarts(this.auth.cust_id);
            });
        },
        clostToast(data) {
          this.flag = data;
        },
    },
    mounted() { 
        this.getCarts(this.auth.cust_id);
        this.flag = false
    }
}
</script>