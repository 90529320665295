<template>
    <div class="mx-auto max-w-screen-xl h-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="mb-4">
            <h1 class="text-lg md:text-xl font-semibold">Order History</h1>
        </div>
        <div class="border border-1 border-gray-300"/>
        <div class="my-1">
            <div 
                v-if="my_orders.length"
                :class="{'h-[400px] overflow-y-scroll' : my_orders.length > 7}"
            >
                <table class="w-full">
                    <thead class="bg-gray-300">
                        <tr>
                            <th class="text-sm w-[60px]">No.</th>
                            <th class="text-sm">Invoice No.</th>
                            <th class="text-sm hidden md:block">Orders</th>
                            <th class="text-sm">Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="even:bg-gray-100 text-center h-[50px]" v-for="(data, index) in my_orders" :key="index">
                            <td class="font-semibold text-sm">{{ index+1 }}</td>
                            <td class="font-semibold text-sm">{{ data.invoice_no }}</td>
                            <td class="hidden md:flex items-center justify-center gap-1" >
                                <div v-for="(cart, index) in data.carts" class="mt-2" :key="index">
                                    <img :src="$static_site(cart.product_image)" class="w-[30px] rounded" alt="">
                                </div>
                            </td>
                            <td class="font-semibold text-xs"> 
                                <span 
                                    class="py-1 px-2 rounded-full"
                                    :class="[
                                        {'bg-yellow-300' : data.status == 'pending'},
                                        {'bg-blue-300 text-white' : data.status == 'to_deliver'},
                                        {'bg-blue-500 text-white' : data.status == 'shipped'},
                                        {'bg-green-300' : data.status == 'completed'}
                                    ]"
                                >
                                    {{ String(data.status).toUpperCase() }}
                                </span>
                            </td>
                            <td>
                                <router-link :to="{ name: 'orderDetail', params: { id: data.id } }">
                                    <Icon icon="mdi:eye" class="size-5 md:size-6 text-blue-300"/>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="mt-4 w-full flex justify-center items-center">
                <h1 class="text-lg md:text-xl font-semibold">No Order History</h1>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import { mapGetters } from 'vuex';
import { Icon } from '@iconify/vue';

export default {
    components: {
        Icon
    },
    data() {
        return {
            my_orders: []
        }
    },
    computed: {
        ...mapGetters({
            auth : 'auth/auth_user'
        })
    },
    methods: {
        async getMyOrders() {
            await axios.get(`/carts/my-orders/${this.auth.cust_id}`).then(res=>{
                this.my_orders = res.data.data;
            }).catch(err=>{});
        }
    },
    mounted() {
        this.getMyOrders();
    }
}
</script>