<template>
    <div class="h-auto rounded border border-gray-100 px-5 py-3">
        <div class="flex justify-between items-center py-3 border-b border-gray-200">
            <span class="text-base md:text-lg lg:text-xl text-gray-800 font-bold">Filters</span>
            <Icon class="text-lg md:text-[1.5rem] font-bold" icon="mdi:equaliser-vertical" />
        </div>
        <div class="py-3 border-b border-gray-200">
            <span class="text-lg font-semibold text-gray-700">Category: </span>
            <fieldset class="space-y-1">
            <div v-if="category.length">
                <label
                    for="DeliveryStandard"
                    class="flex cursor-pointer items-center justify-between gap-4 rounded p-3 text-sm font-medium"
                    >
                    <p class="text-gray-700">All</p>
                    <input
                        id="DeliveryStandard"
                        type="radio"
                        value="all"
                        v-model="selected_category"
                        @change="emitSelectedCategory"
                    />
                </label>
                <div v-for="(data, index) in category" :key="index">
                    <label
                        :for="data.mid_category_id"
                        class="flex cursor-pointer items-center justify-between gap-4 rounded p-3 text-sm font-medium"
                    >
                        <p class="text-gray-700">{{ data.mid_category_name }}</p>
                        <input
                        type="radio"
                        :id="data.mid_category_id"
                        :name="data.mid_category_name"
                        :value="data.mid_category_id"
                        v-model="selected_category"
                        @change="emitSelectedCategory"
                        />
                    </label>
                </div>
            </div>
            </fieldset>
        </div>
    </div>
</template>

<script>
    import axios from '@/plugins/axios';
    import { Icon } from '@iconify/vue';
    
    export default {
        props: ['category', 'sizes'],
        components: { Icon },
        data() {
        return {
            selected_category: 'all',
            selected_size: null,
            filter: {
            price_from: 0,
            price_to: 0,
            category_id: null
            }
        };
        },
        methods: {
            emitSelectedCategory() {

                if(this.selected_category=='all') {
                    this.$router.replace('/meats')
                }

                this.$emit('category_id', this.selected_category);
                this.$router.replace('/meats')
            }
        }
    };
</script>
