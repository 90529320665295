<template>
    <div>
        <div class="bg-red-600 px-4 py-[7px] text-white">
            <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                <div class="flex items-center">
                    <span class="flex items-center text-xs me-2">
                        <Icon icon="mdi:phone" class="me-1"/>02 9629 9333
                    </span>
                    <span class="flex items-center text-xs me-2">
                        <Icon icon="material-symbols:mail" class="me-1"/>
                        wrightstendermeat@gmail.com
                    </span>
                </div>
            </div>
        </div>
        <header class="bg-white">
            <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div class="flex h-16 items-center justify-between">
                    <div class="flex-1 md:flex md:items-center md:gap-12">
                        <router-link to="/" class="block text-teal-600">
                            <span class="sr-only">Home</span>
                            <img :src="$static_site(settings ? settings.logo : 'logo.png')" class="w-[100px]" alt="">
                        </router-link>
                    </div>
        
                    <div class="md:flex md:items-center md:gap-12 me-5">
                        <nav aria-label="Global" class="hidden md:block">
                            <ul class="flex items-center gap-6 text-sm">
                                <li>
                                    <router-link to="/" class="text-gray-500 transition hover:text-gray-500/75">
                                        Home
                                    </router-link>
                                </li>

                                <li>
                                    <router-link to="/about-us" class="text-gray-500 transition hover:text-gray-500/75">
                                        About Us
                                    </router-link>
                                </li>
                    
                                <li>
                                    <router-link to="/our-shop" class="text-gray-500 transition hover:text-gray-500/75">
                                        Our Shop
                                    </router-link>
                                </li>

                                <li>
                                    <router-link to="/meats" class="text-gray-500 transition hover:text-gray-500/75">
                                        Shop Meats
                                    </router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="flex items-center gap-4">
                        <div class="flex items-center gap-2">
                            <!-- <div class="relative">
                                <div class="absolute top-0 right-0 p-1 bg-red-300 rounded-full"></div>
                                <Icon icon="mdi:bell-outline"/>
                            </div> -->
                            <div class="relative">
                                <div class="flex justify-center items-center">
                                    <a href="javascript:;" @click="show_cart_modal = !show_cart_modal; user_dropdown = false">
                                        <Icon icon="mdi:cart-outline"/>
                                    </a>
                                    <div class="rounded-full w-[15px] h-[15px] flex justify-center items-center bg-red-500 text-white p-2 text-[0.6rem]">
                                        {{ user_cart.length }}
                                    </div>
                                </div>

                                <!-- Dropdown -->
                                <div
                                    class="absolute end-[-100px] sm:end-[-10px] z-10 mt-2 rounded w-screen max-w-sm bg-white shadow-lg border border-gray-100 px-4 py-8 sm:px-6 lg:px-8"
                                    aria-modal="true"
                                    role="dialog"
                                    tabindex="-1"
                                    v-if="show_cart_modal"
                                >
                                    <button class="absolute end-4 top-4 text-gray-600 transition hover:scale-110 overflow-none" @click="show_cart_modal=false">
                                        <span class="sr-only">Close cart</span>
                                    
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="size-5"
                                        >
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    <div 
                                        v-if="flag && flag_message"
                                        class="w-full mt-2 p-2 bg-green-400 text-white rounded flex justify-between items-center"
                                    >
                                        <span class="flex items-center">
                                            <Icon icon="mdi:check-circle-outline" class="text-base me-2"/>
                                            {{ flag_message }}
                                        </span>
                                        <Icon icon="mdi:close" @click="flag = false"/>
                                    </div>
                                    <div class="mt-4 space-y-6">
                                        <ul class="space-y-4">
                                            <div v-if="user_cart.length" :class="{
                                                'h-[300px] overflow-y-scroll' : user_cart.length > 4
                                            }">
                                                <li v-for="(data,index) in user_cart" :key="index" class="flex items-center gap-4 my-2">
                                                    <img
                                                        :src="$static_site(data.product_photo)"
                                                        alt=""
                                                        class="size-16 rounded object-cover"
                                                    />
                                            
                                                    <div>
                                                        <h3 class="text-sm text-gray-900">{{data.product_name}}</h3>
                                            
                                                        <dl class="mt-0.5 space-y-px text-[10px] text-gray-600">
                                                            <div>
                                                                <dt class="inline">Category:</dt>
                                                                <dd class="inline">{{ data.mid_category_name }}</dd>
                                                            </div>
                                                
                                                            <div>
                                                                <dt class="inline">Subtotal:</dt>
                                                                <dd class="inline">${{ data.sub_total }}</dd>
                                                            </div>

                                                            <div>
                                                                <dt class="inline">Quantity:</dt>
                                                                <dd class="inline">${{ data.quantity }}</dd>
                                                            </div>
                                                        </dl>
                                                    </div>
                                            
                                                    <div class="flex flex-1 items-center justify-end gap-2">
                                            
                                                        <button class="text-gray-600 transition hover:text-red-600" @click="deleteCartHandler(data.cart_id)">
                                                            <span class="sr-only">Remove item</span>
                                                
                                                            <Icon icon="mdi:trash" class="text-lg"/>
                                                        </button>
                                                    </div>
                                                </li>
                                            </div>
                                            <div v-else class="flex justify-center items-center">
                                                <h1 class="text-lg md:text-xl text-gray-600 font-semibold">No Products in Carts</h1>
                                            </div>
                                            <div v-if="user_cart.length">
                                                <span class="text-lg text-gray-600 font-semibold">Sub-Total: ${{ total }}</span>
                                            </div>
                                        </ul>
                                    
                                        <div class="space-y-4 text-center" v-if="user_cart.length">
                                            <router-link
                                                to="/cart-page"
                                                class="block rounded border border-gray-600 px-5 py-3 text-sm text-gray-600 transition hover:ring-1 hover:ring-gray-400"
                                                @click="show_cart_modal = false"
                                            >
                                                View Cart Page
                                            </router-link>
                                    
                                            <router-link to="/checkout"
                                                href="#"
                                                class="block rounded bg-red-600 px-5 py-3 text-sm text-gray-100 transition hover:bg-red-700"
                                                @click="show_cart_modal = false"
                                            >
                                                Checkout
                                            </router-link>
                                    
                                            <router-link 
                                                to="/meats" 
                                                class="inline-block text-sm text-gray-500 underline underline-offset-4 transition hover:text-gray-600"
                                                @click="show_cart_modal = false"
                                            >
                                                Continue Shopping!
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="relative">
                                <a href="javascript:;" @click="user_dropdown = !user_dropdown; show_cart_modal = false">
                                    <Icon icon="mdi:person-outline"/>
                                </a>

                                <!-- Dropdown -->
                                <div
                                    v-if="user_dropdown"
                                    class="absolute end-[-5px] z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
                                >
                                    <div v-if="is_logged_id" class="p-2">
                                        <router-link
                                            to="/profile"
                                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                        >
                                            Profile
                                        </router-link>

                                        <router-link
                                            to="/my-orders"
                                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                        >
                                            My Orders
                                        </router-link>

                                        <a
                                            href="javascript:;"
                                            @click="logoutUser"
                                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                        >
                                            Logout
                                        </a>
                                    </div>
                                    <div v-else class="p-2">
                                        <router-link
                                            to="/sign-in"
                                            href="#"
                                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                        >
                                            Sign In
                                        </router-link>

                                        <router-link
                                            to="/register"
                                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                        >
                                            Register
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-t border-gray-300 w-full">
                <div class="my-4 flex justify-center md:hidden">
                    <ul class="flex items-center gap-6 text-sm">
                        <li>
                            <router-link to="/" class="text-gray-500 transition hover:text-gray-500/75">
                                Home
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/about-us" class="text-gray-500 transition hover:text-gray-500/75">
                                About Us
                            </router-link>
                        </li>
            
                        <li>
                            <router-link to="/our-shop" class="text-gray-500 transition hover:text-gray-500/75">
                                Our Shop
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/meats" class="text-gray-500 transition hover:text-gray-500/75">
                                Shop Meats
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                    <!-- Search Bar -->
                    <div class="relative py-5">
                        <div class="relative border border-gray-300 rounded">
                            <label for="Search" class="sr-only">Search</label>

                            <input
                                v-model="search_product"
                                @input="searchProductHandler"
                                type="text"
                                id="Search"
                                placeholder="Search for..."
                                class="px-4 w-full rounded-md border-gray-200 py-2.5 pe-10 sm:text-sm outline-black"
                            />
                            <span class="absolute w-20 inset-y-0 end-0 place-content-center flex gap-4 items-center">
                                <Icon icon="mdi:close" v-if="search_product" @click="search_product = null" class="cursor-pointer"/>
                                <button @click="searchProductHandler" type="button" class="text-gray-600 hover:text-gray-700">
                                    <span class="sr-only">Search</span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="size-4"
                                    >
                                        <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                    </svg>
                                </button>
                            </span>

                            <!-- Search Bar dropdown -->
                            <div class="absolute z-10 top-12 md:top-11 w-full p-3 bg-white border border-gray-300 rounded" v-if="search_product">
                                <div class="w-full pb-2 border-b">
                                    <span class="text-xs md:text-sm text-gray-600 font-bold">Products</span>
                                </div>
                                <div>
                                    <div v-if="products.length" :class="{'h-[350px] overflow-y-scroll' : products.length > 5}">
                                        <router-link class="py-4 cursor-pointer flex items-center gap-3" @click="search_product = null" :to="{name: 'detailMeatPage', params:{id: data.product_id}}" v-for="(data, index) in products" :key="index" >
                                            <img :src="$static_site(data.product_image)" class="w-[30px] md:w-[50px]" alt="">
                                            <div>
                                                <p class="font-bold text-base">{{ data.product_name }}</p>
                                                <p class="font-semibold text-xs text-gray-600">start's with: ${{ data.product_current_price }}</p>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div v-else class="flex py-4 justify-center">
                                        <h1 class="text-lg md:text-xl font-semibold">No Products!</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import { Icon } from '@iconify/vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    components: {
        Icon
    },
    data() {
        return {
            user_dropdown   : false,
            show_cart_modal : false,
            user_cart       : [],
            flag            : false,
            flag_message    : null,
            total           : 0,
            category        : [],
            category_sub    : [],
            category_modal_index: null,
            search_product  : null,
            category_id     : null,
            settings        : null,
            products        : []
        }
    },
    watch: {
        carts(newCarts, oldCarts) {
            if(newCarts) {
                this.user_cart = newCarts;
            }
        },
        sub_total(newSubTotal, oldSubTotal) {
            this.total = newSubTotal;
        }
    },
    computed: {
        ...mapGetters({
            carts       : 'cart/carts',
            sub_total   : 'cart/sub_total',
            is_logged_id: 'auth/is_logged_id',
            auth_user   : 'auth/auth_user'
        }),
        ...mapState({
            token : 'auth/token'
        })
    },
    methods: {
        ...mapActions({
            getCarts: 'cart/getUserCarts',
            logout: 'auth/logoutAuthUser'
        }),
        deleteCartHandler(cart_id) {
            axios.delete(`/carts/delete/${cart_id}`).then(res=>{
                this.flag           = true;
                this.flag_message   = "Successfully Deleted!"
                this.getCarts(this.auth_user.cust_id);
            }).catch(err=>{
                this.flag           = false;
                this.flag_message   = "Something went wrong!";
            });
        },
        logoutUser() {
            this.logout().then(res=>{
                if(res) {
                    this.user_cart = [];
                    this.$router.push('/');
                }
            })
        },
        getCategory() {
            axios.get('/category').then(res=>{
                this.category = res.data.data;
            }).catch(err=>{});
        },
        categorySubHandler(i) {
            this.category_sub = this.category.filter((data, index) => {
                return index === i;
            });
        },
        toggleDropdown(index) {
            if (this.category_modal_index === index) {
                this.category_modal_index = null;
            } else {
                this.category_modal_index = index;
            }
        },
        isDropdownOpen(index) {
            return this.category_modal_index === index;
        },
        closeDropdown(index) {
            this.category_modal_index = null;
        },
        searchProductHandler() {
            axios.get('/products/search-products?search='+this.search_product).then(res=>{
                if(res.status == 200) {
                    this.products = res.data.data;
                }
            })
            .catch(err=>{})
        },
        webSettings() {
            axios.get('/settings').then(res=>{
                console.log(res)
                this.settings = res.data.data[0];
            }).catch(err=>{})
        },
    },
    mounted() { 
        if(this.auth_user) {
            this.getCarts(this.auth_user.cust_id);
        }

        this.webSettings();
        // this.getCategory();
    },
    beforeUnmount() {
        document.removeEventListener('click', ()=>{this.show_cart_modal = false});
    },
}
</script>