<template>
    <div class="grid gap-4 grid-cols-2 md:grid-cols-3">
        <div v-for="(count, index) in [1,2,3,4]" :key="index">
            <div class="border border-gray-50 bg-gray-50 shadow rounded-lg p-4 max-w-sm w-full mx-auto">
                <div class="animate-pulse space-x-4">
                    <div class="h-64 w-full rounded-lg bg-gray-100 transition duration-500 group-hover:scale-105 sm:h-72"></div>
                    <div class="mt-5 space-y-3">
                        <div class="grid grid-cols-3 gap-4">
                            <div class="h-2 bg-gray-100 rounded col-span-2"></div>
                            <div class="h-2 bg-gray-100 rounded col-span-1"></div>
                        </div>
                        <div class="h-2 bg-gray-100 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
