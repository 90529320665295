<template>
    <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.8435878443192!2d150.96974027654832!3d-33.71299601100736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a15f11e069ab%3A0x2428e65dae5161e7!2sWright&#39;s%20Tender%20Meats!5e0!3m2!1sen!2sph!4v1726488908300!5m2!1sen!2sph" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            
                <div class="lg:py-24">
                    <h2 class="text-3xl font-bold sm:text-4xl">Our Shop</h2>
            
                    <p class="mt-4 text-gray-600">
                        We invite you to come and experience the difference at Wright's Tender Meats. Our butcher shop is more than just a store; it's a place where quality, tradition, and community come together. We look forward to serving you and helping you create delicious meals for your family and friends. Thank you for choosing Wright's Tender Meats for your meat needs.
                    </p>
        
                    <router-link to="/meats"
                        href="#"
                        class="mt-8 inline-block rounded bg-red-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-red-700 focus:outline-none"
                    >
                        Shop Now
                    </router-link>
                </div>
            </div>
        </div>

        <OnScroll />
    </section>
</template>

<script>
import OnScroll from '@/components/common/OnScroll.vue';
export default {
    components: {
        OnScroll
    }
}
</script>