<template>
    <div class="fixed right-2 bottom-0" v-show="isVisible" @click="scrollToTop">
        <div class="flex items-center justify-center w-[50px] h-[50px] bg-gray-200 shadow border border-red-600">
            <span class="text-4xl text-red-600">
                <Icon icon="mdi:menu-up" />
            </span>
        </div>
    </div>
</template>
<script>
import { Icon } from '@iconify/vue';

export default {
    components: {
        Icon
    },
    data() {
        return {
        isVisible: false,
        };
    },
    methods: {
        handleScroll() {
            this.isVisible = window.scrollY > 200;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>