<template>
    <section class="mx-auto max-w-screen-xl h-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="grid gap-5 grid-cols-1 md:grid-cols-[1fr_0.8fr]">
            <div>
                <!-- Contact Info -->
                <div class="">
                    <h1 class="mb-3 text-lg md:text-xl font-semibold">Contact Info</h1>
                    <div class="relative">
                        <label
                            class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                            >
                            <input
                                v-model="checkout_form.email"
                                type="email"
                                class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                placeholder="Username"
                            />
                            
                            <span
                                class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                            >
                                Email
                            </span>
                        </label>
                    </div>
                </div>
                <!-- Shipping Info -->
                <div class="my-2 md:my-4">
                    <h1 class="mb-3 text-lg md:text-xl font-semibold">Shipping Info</h1>
                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.s_fullname"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Username"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    FullName
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.s_address"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Address
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.s_city"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    City
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.s_country"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Country
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.s_state"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    State
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="grid gap-4 gird-cols-1 md:grid-cols-2">
                        <div class="mb-2 md:mb-4">
                            <div class="relative">
                                <label
                                    class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                    >
                                    <input
                                        v-model="checkout_form.s_code"
                                        type="text"
                                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                        placeholder="Address"
                                    />
                                    
                                    <span
                                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                    >
                                        ZIP
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="mb-2 md:mb-4">
                            <div class="relative">
                                <label
                                    class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                    >
                                    <input
                                        v-model="checkout_form.s_phone"
                                        type="text"
                                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                        placeholder="Address"
                                    />
                                    
                                    <span
                                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                    >
                                        Phone
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-2 border border-1 border-gray-300 w-full" />


                <!-- Billing Info -->
                <div class="my-2 md:my-4">
                    <h1 class="mb-3 text-lg md:text-xl font-semibold">Billing Info</h1>
                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.b_fullname"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Username"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    FullName
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.b_address"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Address
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.b_city"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    City
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.b_country"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    Country
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="mb-2 md:mb-4">
                        <div class="relative">
                            <label
                                class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                >
                                <input
                                    v-model="checkout_form.b_state"
                                    type="text"
                                    class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                    placeholder="Address"
                                />
                                
                                <span
                                    class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                >
                                    State
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="grid gap-4 gird-cols-1 md:grid-cols-2">
                        <div class="mb-2 md:mb-4">
                            <div class="relative">
                                <label
                                    class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                    >
                                    <input
                                        v-model="checkout_form.b_code"
                                        type="text"
                                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                        placeholder="Address"
                                    />
                                    
                                    <span
                                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                    >
                                        ZIP
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="mb-2 md:mb-4">
                            <div class="relative">
                                <label
                                    class="relative block rounded-md border border-gray-200 focus-within:border-black focus-within:ring-1 focus-within:ring-black"
                                    >
                                    <input
                                        v-model="checkout_form.b_phone"
                                        type="text"
                                        class="w-full peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 px-5 py-3"
                                        placeholder="Address"
                                    />
                                    
                                    <span
                                        class="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs"
                                    >
                                        Phone
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-2 border border-1 border-gray-300 w-full" />

                <!-- Payment Method -->
                <div>
                    <h1 class="mb-3 text-lg md:text-xl font-semibold">Payment</h1>
                    <div class="mb-2 md:mb-4">
                        <div id="card-element">
                            <!-- Stripe Element will be inserted here -->
                        </div>
                        <div v-if="errorMessage">{{ errorMessage }}</div>
                    </div>
                </div>

                <div class="my-2 border border-1 border-gray-300 w-full" />

                <!-- Receive Option -->
                <div>
                    <h1 class="mb-3 text-lg md:text-xl font-semibold">Receive Option</h1>
                    <div class="mb-2 md:mb-4">
                        <div class="grid gap-3 grid-cols-1 md:grid-cols-2">
                            <label
                                for="Option1"
                                class="flex cursor-pointer items-start gap-4 rounded-lg border border-gray-200 p-4 transition hover:bg-gray-50"
                            >
                                <div class="flex items-center">
                                    &#8203;
                                    <input type="radio" class="size-4 rounded border-gray-300" id="Option1" checked value="deliver" v-model="receive_option" />
                                </div>
                                <strong class="font-medium text-gray-900"> For Deliver </strong>
                            </label>

                            <label
                                for="Option2"
                                class="flex cursor-pointer items-start gap-4 rounded-lg border border-gray-200 p-4 transition hover:bg-gray-50"
                            >
                                <div class="flex items-center">
                                    &#8203;
                                    <input type="radio" class="size-4 rounded border-gray-300" id="Option2" value="pick-up" v-model="receive_option" />
                                </div>
                                <strong class="font-medium text-gray-900"> To Pick-Up </strong>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4">
                <div class="border border-gray-300 rounded p-4">
                    <div class="my-2">
                        <div 
                            class="space-y-4" v-if="user_carts.length"
                        >
                            <div 
                                class="h-[300px]"
                                :class="{
                                    'overflow-y-scroll' : user_carts.length > 4
                                }"
                            >
                                <li v-for="(data,index) in user_carts" :key="index" class="flex items-center gap-4 my-2" 
                                    
                                >
                                    <img
                                        :src="$static_site(data.product_photo)"
                                        alt=""
                                        class="size-16 rounded object-cover"
                                    />
                            
                                    <div>
                                        <h3 class="text-sm text-gray-900">{{data.product_name}}</h3>
                            
                                        <dl class="mt-0.5 space-y-px text-[10px] text-gray-600">
                                            <div>
                                                <dt class="inline">Category:</dt>
                                                <dd class="inline">{{ data.mid_category_name }}</dd>
                                            </div>
                                
                                            <div>
                                                <dt class="inline">Subtotal:</dt>
                                                <dd class="inline">{{ data.sub_total }}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 pt-2 border-t">
                        <span class="text-base md:text-lg font-semibold">Total Cost: ${{ sub_total }}</span>
                    </div>
                    <div class="my-2">
                        <button
                            class="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-center text-white shadow hover:bg-red-700 focus:outline-none active:bg-red-500 sm:w-auto"
                            type="button"
                            @click="submitHandler"
                            :disabled="loading"
                        >
                            <span v-if="!loading">Sign In</span>
                            <span v-else>Loading...</span>
                        </button>
                    </div>
                    <span v-if="payment_error" class="text-sm text-red-500">{{ payment_error }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import axios from '@/plugins/axios';
import { data } from 'autoprefixer';

export default {
    name: 'checkoutView',
    data() {
        return {
            authenticated_user: null,
            checkout_form: {
                email: null,
                s_fullname: null,
                s_phone: null,
                s_country: null,
                s_address: null,
                s_city: null,
                s_state: null,
                s_zip: null,
                b_fullname: null,
                b_phone: null,
                b_country: null,
                b_address: null,
                b_city: null,
                b_state: null,
                b_zip: null
            },
            stripe: null,
            cardElement: null,
            clientSecret: null,
            errorMessage: null,
            loading: false,
            carts: [],
            user : [],
            receive_option: 'deliver',
            loading: false,
            payment_error: null
        }
    },
    computed: {
        ...mapGetters({
            user_carts  : 'cart/carts',
            sub_total   : 'cart/sub_total'
        })
    },
    watch: {
        sub_total(newSubTotal, oldSubTotal) {
            return newSubTotal;
        },
    },
    methods: {
        ...mapActions({
            getUserCarts: 'cart/getUserCarts'
        }),
        initCheckoutUserData() {
            this.checkout_form.email                = this.user.email;
            this.checkout_form.s_fullname           = this.user.cust_s_name
            this.checkout_form.s_address            = this.user.cust_s_address;
            this.checkout_form.s_phone              = this.user.cust_s_phone;
            this.checkout_form.s_state              = this.user.cust_s_state;
            this.checkout_form.s_city               = this.user.cust_s_city;
            this.checkout_form.s_country            = this.user.cust_s_country;
            this.checkout_form.s_zip                = this.user.cust_s_zip;

            this.checkout_form.b_fullname       = this.user.cust_b_name
            this.checkout_form.b_address        = this.user.cust_b_address;
            this.checkout_form.b_phone          = this.user.cust_b_phone;
            this.checkout_form.b_state          = this.user.cust_b_state;
            this.checkout_form.b_city           = this.user.cust_b_city;
            this.checkout_form.b_country        = this.user.cust_b_country;
            this.checkout_form.b_zip            = this.user.cust_b_zip;
        },
        async submitHandler() {
            this.payment_error = null
            this.loading = true;
            let filtered_carts = this.user_carts.map(data => data.cart_id);
            const payload = {
                total_cost: this.sub_total,
                carts: filtered_carts,
                user_id: this.authenticated_user.cust_id,
                data: this.checkout_form,
                receive_option: this.receive_option
            };

            try {
                // Create PaymentIntent on the server
                const response = await axios.post('/checkout', payload);
                const clientSecret = response.data.clientSecret; // Assuming your server returns this

                // Confirm the payment on the client side
                const { error } = await this.stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.checkout_form.s_fullname,
                            email: this.checkout_form.email
                        },
                    },
                });

                if (error) {
                    this.payment_error = 'Payment confirmation error:' + error.message
                    console.error('Payment confirmation error:', error.message);
                    // Handle error (e.g., display an error message to the user)
                } else {
                    // Payment succeeded
                    console.log('Payment successful!', response);
                    axios.post('/update-cart', payload).then(res=>{
                        if(res.status == 201) {
                            this.$router.push('/invoice/'+res.data.data.id)
                        }
                    })
                    .catch(err=>{});
                }
            } catch (err) {
                console.error('Checkout error:', err);
            }

            this.getUserCarts(this.authenticated_user.cust_id);
            this.loading = false;
        }
    },
    async mounted() {
        if (this.user_carts.length < 1) {
            this.$router.push('/meats');
            return;
        }

        this.authenticated_user = this.$store.state.auth.auth_user;
        this.stripe = await loadStripe('pk_live_51O3ZDNH1siCNhhpMcKzIb6Og7h3lY9PwwbTeemS6lTEUjW2ytOHlJGqlgrPB8cKiaj4muJgCLP8JlpGstn5ujICR00xIFhEed4');

        const elements = this.stripe.elements();
        this.cardElement = elements.create('card');
        this.cardElement.mount('#card-element');

        axios.get('/customer/' + this.authenticated_user.cust_id)
        .then(res => {
            this.user = res.data.data;
            console.log(this.user);

            // Populate checkout_form after data is retrieved
            this.checkout_form.email = this.user.email;
            this.checkout_form.s_fullname = this.user.cust_s_name;
            this.checkout_form.s_address = this.user.cust_s_address;
            this.checkout_form.s_phone = this.user.cust_s_phone;
            this.checkout_form.s_state = this.user.cust_s_state;
            this.checkout_form.s_city = this.user.cust_s_city;
            this.checkout_form.s_country = this.user.cust_s_country;
            this.checkout_form.s_code = this.user.cust_s_zip;

            this.checkout_form.b_fullname = this.user.cust_b_name;
            this.checkout_form.b_address = this.user.cust_b_address;
            this.checkout_form.b_phone = this.user.cust_b_phone;
            this.checkout_form.b_state = this.user.cust_b_state;
            this.checkout_form.b_city = this.user.cust_b_city;
            this.checkout_form.b_country = this.user.cust_b_country;
            this.checkout_form.b_code = this.user.cust_b_zip;
        })
        .catch(err => {
            console.error("Error fetching customer data:", err);
        });

        this.initCheckoutUserData();
    }
}
</script>

<style scoped>
#card-element {
    border: 1px solid #d1d1d1;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: text;
}
</style>